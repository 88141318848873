import bsc from "../images/bsc.png"
import polygon from "../images/polygon.png"
import bsctestnet from "../images/bsctestnet.png"
import eth from "../images/eth.png"
import ropsten from "../images/ropsten.png"
// export const RPC_URL = "https://bsc-dataseed1.binance.org/";
// export const CHAIN_ID = 56;
  
export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";
 export const CHAIN_ID = 97;
 
 export const ONEDAYINSECONDS = 86400;

 export const userFee = 5

 export const userFeeWithtoken = 4

 export const CHAINS = [
    {
        NAME: "Avalanche-TEST",
        RPC_URL:"https://api.avax-test.network/ext/bc/C/rpc",
        CHAIN_ID:43113,
        IMAGE: bsctestnet,
        ROUTER: "0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
        // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
        LIQUIDITY_TOKEN_ADDRESS : "0xf6925d546603FB10A88b493DABfA71f341a56d1e",
        STANDARD_TOKEN_ADDRESS : "0xAdf85f277B0e1D0A92092724Bb5901Db2FC33155",
        LOCK_ADDRESS : "0xa86fcd08e7692Ab47c74D45b7CdDEf98673f8191", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
        BNB : "0x0000000000000000000000000000000000000000",
        BUSD : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
        USDT : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
        USDC : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
        AIRDROP_ADDRESS : "0x97e4CE03d3452bf984008cf663b9f993ACe17E54", //"0x385b0e7d746ed4711ed3e7bea48e2d9b5052373a",
        TRANSACTION : "https://testnet.snowtrace.io/tx/",
        TokenTracker : "https://testnet.snowtrace.io/token/"
    },
    {
        NAME: "Arbitrum",
        RPC_URL:"https://arb1.arbitrum.io/rpc",
        CHAIN_ID:42161,
        IMAGE: bsctestnet,
        ROUTER: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506", //pancake
        // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
        LIQUIDITY_TOKEN_ADDRESS : "0xf997125D111066E1989c94f18bA097C20ca858Ff",
        STANDARD_TOKEN_ADDRESS : "0xc228c9AB8889901C7C6936C6C6B8d5547006d727",
        LOCK_ADDRESS : "0x500d3cB1a8709e06C6274CbFa952E4F2a13dd87d", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
        // BNB : "0x0000000000000000000000000000000000000000",
        ETH : "0x0000000000000000000000000000000000000000",
        DAI  : "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
        USDT : "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
        USDC : "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
        TRANSACTION : "https://arbiscan.io/tx/",
        TokenTracker : "https://arbiscan.io/token/" 
    },
];

// export const API_URL = "http://localhost:7097"

export const API_URL = "http://43.204.99.101:7097"

// export const API_URL = "https://api.spaceai.finance"

export const FUND_RECEIVER = "0x12ABeD5514FD6F6716F1154a1Be05beD9E1a11dF"

export const COINS = ["ETH" , "DAI" , "USDT" , "USDC" , "ETH" , "MATIC" , "AVAX" , "CRO" , "FTM"];

export const HC = ["Presale" , "Fairlaunch" , "Auction" , "Subscription"];

export const STATUS = ["Upcoming" , "Live" , "Ended"];

export const TOKEN_FEE = 0.01;

export const ZEROTH_ADDRESS = "0x0000000000000000000000000000000000000000"


// export const WBNB_Address = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; // BSC Mainet

// export const WBNB_Address = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";  //BSC Testnet

export const WBNB_Address = "0x1d308089a2d1ced3f1ce36b1fcaf815b07217be3"

export const PROJECT_ID = "b8a1daa2dd22335a2fe1d2e139980ae0"
