import React,{Component} from 'react'
import { Col, Container,Row } from 'react-bootstrap'
import { BiLogoFacebookCircle } from 'react-icons/bi';
import { AiFillTwitterSquare } from 'react-icons/ai';
import { AiOutlineInstagram } from 'react-icons/ai';
import { Link } from 'react-router-dom';


class NewHome extends Component {

    darkTheme() {
        document.body.classList.remove('light_theme');
        document.body.classList.add('dark_theme');
        document.getElementById("sun_icon").classList.remove('active');
        document.getElementById("moon_icon").classList.add('active');
        // document.getElementById("sun_icon_mob").classList.remove('active'); 
        // document.getElementById("moon_icon_mob").classList.add('active'); 
        localStorage.setItem("theme", 'dark_theme');


    }
    lightTheme() {
        document.body.classList.remove('dark_theme');
        document.body.classList.add('light_theme');
        document.getElementById("moon_icon").classList.remove('active');
        document.getElementById("sun_icon").classList.add('active');
        // document.getElementById("moon_icon_mob").classList.remove('active'); 
        // document.getElementById("sun_icon_mob").classList.add('active');
        localStorage.setItem("theme", 'light_theme');


    }

    render() {
    
  return (
    <div className='landing_banner'>
    
    <Container fluid>
        <Container>
    <div className='header_top'>
        <div className='logo_landing_home'>
                <img className='mainlogo_img' src={require('../images/bitdeals/main_logo.png').default}/>
                </div>
<div>
<Link to="/home"><button className="get-started-btn mr-2 text-uppercase" >Use App</button></Link>
<i className="fa fa-sun-o theme_icon" aria-hidden="true" id="sun_icon" onClick={() => this.lightTheme()}></i>
                    <span className='px-1 text_splash'>/</span>
                    <i class="fa fa-moon-o theme_icon active" aria-hidden="true" id="moon_icon" onClick={() => this.darkTheme()}></i>
                   
</div>
                
            </div>
            </Container>
        <Container>
          

            <Row className='h-100 banner_toprow'>
                <Col className='banner_left_dtls mb-3' xs={12} sm={12} md={4}>
                <h3 className="banner_left_ttl">BITDEALS IDO</h3>
                <p className='banner_left_hint'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled</p>
                <button className="get-started-btn mr-2 text-uppercase" >Create Now</button>
                    <img className=' banner_skyblueblur' src={require('../images/bitdeals/roundskyblue.png').default}/>
                    <img className='banner_yellowblur' src={require('../images/bitdeals/roundyellow.png').default}/>
                        

                </Col>
                <Col xs={12} sm={12} md={8}>
                    <img className='img-fluid' src={require('../images/bitdeals/bigrocket.png').default}/>
                </Col>
            </Row>

            <Row className='h-100 reverse_row'>
            
                <Col className='mb-5'  xs={12} sm={12} md={5}>
                <img className='girl_rocketimg img-fluid' src={require('../images/bitdeals/girlrocket.png').default}/>
                </Col>
                <Col className='mb-5' xs={12} sm={12} md={7}>
                <div className='relative_bitdealts'>
                <img className='second_yellowblur' src={require('../images/bitdeals/roundyellow.png').default}/>
                </div>
               
                    <p className='why_bitdeals_txt'>why <span className='bitdeals_why'>BITDEALS IDO</span> ?</p>
                    <p className='banner_left_hint'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    <p className='banner_left_hint'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    <button className="get-started-btn  text-uppercase" >
                      Get Started
                        </button>
                       

                </Col>
            </Row>
            

            <Row className='mt-5 justify-content-center'>
            <div className='relative_bitdealts w-100'>
                <img className='features_yellowblur' src={require('../images/bitdeals/roundyellow.png').default}/>
                </div>
                <Col xs={12} sm={12} md={6} className=''>
                   
                <div className='w-100'>
            <p className='why_bitdeals_txt text-center'>Features of <span className='bitdeals_why'>BITDEALS IDO</span></p>
            <p className='banner_left_hint text-center'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an </p>
           
            

            </div>
            </Col>
            </Row>

            <Row className='mt-5'>
                <Col className='mb-3'  xs={12} sm={12} md={4}>
                    <div className='thunder_whole'>
                    
                <img className='bitdealts_yellowblur' src={require('../images/bitdeals/roundyellow.png').default}/>
               
                    <img className='img-fluid' src={require('../images/bitdeals/thunder.png').default}/>
                    <p className='thunder_boldtxt'>lorem ipsum_1</p>
                    <p className='banner_left_hint text-center'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an </p>
                   
                    </div>
                </Col>
                <Col className='mb-3' xs={12} sm={12} md={4}>
                    <div className='thunder_whole'>
                    <img className='img-fluid' src={require('../images/bitdeals/thunder.png').default}/>
                    <p className='thunder_boldtxt'>lorem ipsum_1</p>
                    <p className='banner_left_hint text-center'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an </p>
                    </div>
                </Col>
                <Col className='mb-3' xs={12} sm={12} md={4}>
                    <div className='thunder_whole'>
                    <img className='img-fluid' src={require('../images/bitdeals/thunder.png').default}/>
                    <p className='thunder_boldtxt'>lorem ipsum_1</p>
                    <p className='banner_left_hint text-center'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an </p>
                    </div>
                </Col>
            </Row>

        </Container>
    </Container>
    <hr className='green_hrline'/>

    <Container fluid>
    
   
        <Container>
           
            <Row>
               
            <Col xs={12} lg={4}></Col>
                <Col xs={12} lg={8}>
               
                    <div className='bottom_footer'>
                         <p className='banner_left_hint copyright_txt'>COPY RIGHT @ 2023. aLL RIGHTS RESERVED</p>
                    <div className='social_icons_bottomfoot'>
                    <BiLogoFacebookCircle className='footer_social_logos'/>
                    <AiFillTwitterSquare className='footer_social_logos'/>
                    <AiOutlineInstagram className='footer_social_logos'/>
                    <img className='footer_yellowblur' src={require('../images/bitdeals/roundyellow.png').default}/>
                    </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </Container>
    </div>
  )
}
}

export default NewHome